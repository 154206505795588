














































import {Component, Vue, Watch} from "vue-property-decorator";
import {UpdateUserMutation, MeQuery} from "@/graphql/auth";

@Component
export default class CreateAvatarPage extends Vue{
  clicked = false;

  image: null | File = null;
  image_url: null | string = null;
  image_base64: null | ArrayBuffer | string = null;

  get hasAvatar() {
    if(this.$store.state.me.avatar) {
      return true;
    } else {
      return false;
    }
  }

  get me() {
    return this.$store.state.me;
  }

  @Watch('image')
  onPropertyChanged(image: null | File) {
    if(image !== null) {
      this.image_url = URL.createObjectURL(image);
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        this.image_base64 = reader.result;
      };
    }
  }

  clearUpload() {
    this.image = null;
    this.image_url = null;
    this.image_base64 = null;
  }

  /* Needed to to be able to call `this.$parent.close()` below,
     * see https://github.com/buefy/buefy/issues/1184
     */
  public $parent: any;

  save() {
    if(this.hasAvatar) {
      this.replaceOldAvatar();
    } else {
      this.uploadFirstAvatar();
    }
  }

  replaceOldAvatar() {
    this.$apollo
      .mutate({
        mutation: UpdateUserMutation,
        variables: {
          id: this.$store.state.me.id,
          avatar: null,
        },
        refetchQueries: [{query: MeQuery}],
      })
      .then(_ => {
        this.uploadFirstAvatar();
      })
      .catch(error => {
        console.error(error);
      })
  }

  uploadFirstAvatar() {
    this.clicked = true;
    this.$apollo
      .mutate({
        mutation: UpdateUserMutation,
        variables: {
          id: this.$store.state.me.id,
          avatar: this.image_base64,
        },
        refetchQueries: [{query: MeQuery}],
      })
      .then(_ => {
        this.$forceUpdate();
        location.reload(true);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.clicked = false;
        this.$router.push({name: 'workspace-create'});
      });
  }
}
